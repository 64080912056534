import { useState } from 'react';
import {PopupModal} from "react-calendly";

import './CalendarButton.css'

function CalendarButton() {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        className='calendar-button'
        onClick={() => setIsOpen(true)}>
        Click to Schedule
      </button>
      <PopupModal
        url="https://calendly.com/koolbeach/trailer-rental"
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
    </div>


  );
}

export default CalendarButton;
