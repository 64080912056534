import React from 'react'
import CalenderButton from './CalenderButton';
import './styles.css';

const HomepageLayout = () => (
  <body id="page-top">
  <header className="masthead bg-primary hero text-white text-center">
    <div className="container d-flex align-items-center flex-column">
      <h1 className="masthead-heading text-uppercase mb-0">Cuerny Trailer</h1>
      <div className="divider-custom divider-light">
        <div className="divider-custom-line"></div>
        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
        <div className="divider-custom-line"></div>
      </div>
      <p className="masthead-subheading font-weight-light mb-0">Local Utility Trailer Rental</p>
      <p className="masthead-subheading font-weight-light mb-0">$65/day</p>
    </div>

    <div style={{padding: '20px'}}>
      <CalenderButton/>
    </div>

  </header>
  <section className="page-section portfolio" id="portfolio">
    <div className="container">
      <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Trailer Specs</h2>
      <div className="divider-custom">
        <div className="divider-custom-line"></div>
        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
        <div className="divider-custom-line"></div>
      </div>
      <div className='text-center'>
        <div>83" width x 16' length</div>
        <div>Double axel</div>
        <div>Trailer brakes</div>
        <div>7 pin connector - with converter if you have 5 pin</div>
      </div>
    </div>
    <div style={{padding: '40px 0', textAlign: 'center'}}>
      <CalenderButton/>
      <h4 style={{padding: '40px'}}>
        $65/day
      </h4>
    </div>
  </section>
  <div className="copyright py-4 text-center text-white">
    <div className="container"><small>Email: <a href="mailto:mike.w.kool@gmail.com">mike.w.kool@gmail.com</a></small></div>
  </div>
  </body>
)

export default HomepageLayout
