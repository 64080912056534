import './App.css';
import HomepageLayout from "./HomepageLayout"

function App() {
  return (
    <div className='App'>
      <HomepageLayout/>
    </div>
  );
}

export default App;
